import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import {PhoneIcon} from '@heroicons/react/24/outline';
import {validatePassword} from '../utils/validation';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPhoneReset, setIsPhoneReset] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [smsOtpCountdown, setSmsOtpCountdown] = useState(0);
  const [isPhoneOtpVerified, setIsPhoneOtpVerified] = useState(false);
  const [isPhoneOtpFieldEnabled, setIsPhoneOtpFieldEnabled] = useState(false);
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  useEffect(() => {
    let smsOtpInterval = null;
    if (smsOtpCountdown > 0) {
      smsOtpInterval = setInterval(() => {
        setSmsOtpCountdown(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(smsOtpInterval);
  }, [smsOtpCountdown]);

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);

      if (!validateEmail(email)) {
        setError(translate(language, 'forgotPassword.invalidEmail'));
        setLoading(false);
        return;
      }

      const response = await axios.post('/api/auth/forgotPassword', {email});
      if (response.status === 200) {
        navigate('/message', {
          state: {
            message: translate(language, 'forgotPassword.checkEmail'),
            buttonText: translate(language, 'forgotPassword.returnToLogin'),
            buttonLink: '/login',
          },
        });
      } else {
        setError(translate(language, 'forgotPassword.error'));
      }
    } catch (error) {
      console.error(error);
      setError(translate(language, 'forgotPassword.error'));
    } finally {
      setLoading(false);
    }
  };

  const validatePhone = phone => {
    return /^\d{8}$/.test(phone);
  };

  const handleSendPhoneOtp = async () => {
    if (!validatePhone(phone)) {
      setError(translate(language, 'signup.invalidHongKongPhone'));
      return;
    }
    try {
      setSmsOtpCountdown(60);
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/resetPasswordSendSMSOTP`,
        {
          phone,
        },
      );
      setIsPhoneOtpFieldEnabled(true);
    } catch (error) {
      setError(translate(language, 'signup.failedToSendSMSOTP'));
    }
  };

  const handleVerifyPhoneOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/resetPasswordVerifySMSOTP`,
        {
          phone,
          otp: phoneOtp,
        },
      );
      if (response.status === 200) {
        setIsPhoneOtpVerified(true);
      }
    } catch (error) {
      setError(translate(language, 'signup.invalidSMSOTP'));
    }
  };

  const handlePhonePasswordReset = async () => {
    try {
      setLoading(true);
      setError('');

      if (password !== retypePassword) {
        setError(translate(language, 'resetPassword.passwordMismatch'));
        return;
      }

      if (!validatePassword(password)) {
        setError(translate(language, 'resetPassword.weakPassword'));
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/resetPasswordByPhone`,
        {
          phone,
          newPassword: password,
        },
      );

      navigate('/message', {
        state: {
          message: translate(language, 'resetPassword.success'),
          buttonText: translate(language, 'resetPassword.returnToLogin'),
          buttonLink: '/login',
        },
      });
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(translate(language, 'resetPassword.failed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[80vh] p-5 text-center">
      <div className="font-bold mb-5">
        {translate(language, 'forgotPassword.headerText')}
      </div>

      <button
        onClick={() => setIsPhoneReset(!isPhoneReset)}
        className="flex items-center gap-2 mb-4 px-4 py-2 rounded-lg border border-gray-300 hover:bg-gray-50">
        <PhoneIcon className="w-5 h-5" />
        {isPhoneReset
          ? translate(language, 'forgotPassword.resetByEmail')
          : translate(language, 'forgotPassword.resetByPhone')}
      </button>

      <div className="font-regular mb-2.5">
        {isPhoneReset
          ? translate(language, 'forgotPassword.phoneResetDescription')
          : translate(language, 'forgotPassword.subText')}
      </div>

      {error && <div className="text-red-500">{error}</div>}

      {!isPhoneReset && (
        <>
          <input
            className="w-full h-10 my-2.5 px-4 border border-gray-300 rounded-[20px] bg-white font-regular"
            type="text"
            placeholder={translate(language, 'forgotPassword.emailPlaceholder')}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          {loading ? (
            <CircularProgress />
          ) : (
            <button
              className="w-full h-10 mt-5 px-4 rounded-[20px] bg-[#cdeee3] text-black font-regular cursor-pointer"
              onClick={handleForgotPassword}>
              {translate(language, 'forgotPassword.submit')}
            </button>
          )}
        </>
      )}

      {isPhoneReset && (
        <>
          <div className="flex w-full gap-2 mt-4">
            <input
              type="text"
              className="flex-grow px-4 py-2 border rounded-lg"
              placeholder={translate(language, 'signup.phone')}
              value={phone}
              onChange={e => setPhone(e.target.value)}
              disabled={isPhoneOtpVerified}
            />
            <button
              onClick={handleSendPhoneOtp}
              disabled={
                !phone ||
                !validatePhone(phone) ||
                smsOtpCountdown > 0 ||
                isPhoneOtpVerified
              }
              className="px-4 py-2 text-white bg-blue-500 rounded-lg disabled:bg-gray-300">
              {smsOtpCountdown > 0
                ? `${translate(language, 'signup.wait')} ${smsOtpCountdown}s`
                : translate(language, 'signup.send')}
            </button>
          </div>
          <div className="flex w-full gap-2 mt-4">
            <input
              type="text"
              className="flex-grow px-4 py-2 border rounded-lg"
              placeholder={translate(language, 'signup.enterSmsOtp')}
              value={phoneOtp}
              onChange={e => setPhoneOtp(e.target.value)}
              disabled={!isPhoneOtpFieldEnabled || isPhoneOtpVerified}
            />
            <button
              onClick={handleVerifyPhoneOtp}
              disabled={!phoneOtp || isPhoneOtpVerified}
              className="px-4 py-2 text-white bg-blue-500 rounded-lg disabled:bg-gray-300">
              {isPhoneOtpVerified ? '✔' : translate(language, 'signup.verify')}
            </button>
          </div>
          {isPhoneOtpVerified && (
            <>
              <input
                type="password"
                className="w-full px-4 py-2 mt-4 border rounded-lg"
                placeholder={translate(language, 'signup.password')}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <input
                type="password"
                className="w-full px-4 py-2 mt-4 border rounded-lg"
                placeholder={translate(language, 'signup.retypePassword')}
                value={retypePassword}
                onChange={e => setRetypePassword(e.target.value)}
              />
              {loading ? (
                <CircularProgress className="mt-4" />
              ) : (
                <button
                  onClick={handlePhonePasswordReset}
                  className="w-full h-10 mt-4 px-4 rounded-[20px] bg-[#3ddbd1] text-white font-regular 
                           disabled:bg-gray-300 disabled:cursor-not-allowed"
                  disabled={
                    !password || !retypePassword || password !== retypePassword
                  }>
                  {translate(language, 'resetPassword.reset')}
                </button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
